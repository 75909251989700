<template>
  <div class="about page">
    <section id="banner" class="align-middle">
      <banner-image-overlay></banner-image-overlay>
      <h2 class="title">MySpanishNow Story</h2>
    </section>
    <div class="container 75%">
      <section class="row 200%">
        <div class="worker">
          <h4 class="name">María Ángeles Bueno Castaño
            <a href="https://www.linkedin.com/in/maria-angeles-bueno-castano/" target="_blank">
              <i class="fab fa-linkedin-square"></i>
            </a>
          </h4>
          <span class="role">Founder and Teacher at MySpanishNow</span>
        </div>
        <p>Hola, my name is María Ángeles , I am the founder and online Spanish teacher at MySpanishNow. I am from Extremadura. My hometown  is located in a rural region in the Southwest of Spain. I grew up surrounded by beautiful landscapes. I guess that’s why nature is very important to me. In fact, at the moment I live in Scotland - surrounded by trees, ocean and mountains. In the region of Scotland that I live, Fife, I collaborate as a volunteer in ecological projects of reuse and recycle.</p>
        <p>🫶 I invite you to visit both Extremadura and Scotland.</p>
        <figure>
          <img src="/images/about-eilean-donan-castle-scotland.jpg" alt="Me in Belfast" loading="lazy"/>
          <figcaption>Exploring beautiful places in Scotland - Eilean Donan Castle</figcaption>
        </figure>
      </section>
      <section class="row 200%">
        <h3>¿Why @MySpanishNow?</h3>
        <p>MySpanishNow was born of the necessity of helping people around the UK to learn and/or improve their Spanish from home. We want to <b>help you to communicate with your Spanish family and friends</b>, without feeling frustrated, embarrassed or unable to express your feelings.</p>
        <p>If you have a family dinner, a wedding, your parents in law are coming from Spain to visit you and you don’t even know how to welcome them or if you want to meet new people but the language is a barrier 😩. Don’t worry, I know exactly how you feel! I had that same exact feeling when I met my parents in law for the first time!!</p>
      </section>
      <section class="row 200%">
        <div class="col-lg-12">
          <h4>How did it all start?</h4>
        </div>
        <div class="col-lg-8">
          <p>It all started in Belfast, Northern Ireland, an amazing place, where I lived nearly 4 years. There I discovered my passion for teaching my own language - culture and traditions – as well as it was where I found my love, my husband. He is Portuguese, and so, I remember very well how difficult it was during my first Christmas dinner in Portugal, where I wasn’t able to speak the main language. Everybody was talking at the same time and asking me questions that I was not able to answer or at least not as good as I would like. I wanted to express myself, say how good the food was, the decoration, but no words came to my mind. The same happened when I was invited to a wedding and I struggled to socialise with my husband’s friends.</p>
        </div>
        <div class="col-lg-4">
          <figure style="max-width: 500px">
            <img src="/images/about-belfast.jpg" alt="Me in Belfast" loading="lazy"/>
            <figcaption>Me in belfast. Where it all started!</figcaption>
          </figure>
        </div>
        <p>During my first years in Belfast I worked as a Spanish Assistant. This was an enriching experience, because  in this position I could see that many students needed to have more conversational lessons, more speaking. They needed to practise and that’s when I started to realise that this necessity could be extensible to more people. That’s why my lessons are 100% conversational and one-to-one. Personalised lessons that give you the opportunity <b>to practise more and more</b> in order to make you feel confident day-by-day with your Spanish.</p>
        <p>For all these experiences I feel the necessity of helping others, because I know in <b>first person</b> how frustrating it can be to not be able to communicate with others.</p>
        <p>Belfast gave me everything, both professionally and personally! 🍀</p>
      </section>
      <section class="row 200%">
        <h4>My dream has come true</h4>
        <p>At the end of 2019, I moved to Scotland where I was working in a high school but in 2020, COVID arrived, and that’s when I realised that my project of creating a school to help others could be online. This would be perfect to reach more people like you (i.e. with the same necessity). People with the same interests all over the world, even those in remote areas, could learn Spanish in MySpanishNow thanks to the technology and all the tools we have around.</p>
        <p>And with that in mind, MySpanishNow has become a reality. 🎉</p>
      </section>
    </div>
    <section id="bottom" class="wrapper style2 special">
      <div class="container">
        <header class="major">
          <h2>Do you dare to learn SpanishNow?</h2>
        </header>
        <ul class="actions">
          <li><router-link :to="{ name: 'Register' }" target="_blank"
            class="button special big">Register and get a Free lesson</router-link></li>
        </ul>
      </div>
    </section>
  </div>
</template>

<script>
import BannerImageOverlay from '../../components/common/utils/BannerImageOverlay.vue';

export default {
  name: 'AboutMe',
  components: {
    BannerImageOverlay,
  },
};
</script>

<style scoped>
  #bottom header.major {
    margin-bottom: 0;
  }

  @media screen and (max-width: 980px) {
    #bottom .actions {
      margin: 0;
    }
  }
  .worker {
    width: 100%;
    margin-bottom: 20px;
  }

  .worker .name {
    margin-bottom: 0;
  }

  .worker .name i {
    margin-left: 5px;
    font-size: 25px;
    color: #0273b1;
  }

  .worker .role {
    font-style: italic;
    font-size: 0.9em;
  }

  section img {
    border-radius: 25px;
    margin: auto;
    width: 100%;
  }

  figure {
    max-width: 100%;
    margin: 0 0 2em 0;
  }

  figcaption {
    font-size: 0.85em;
    font-style: italic;
    margin-top: -10px;
    text-align: center;
  }

  .about > .container {
    margin-top: 50px;
  }

  section > h3 {
    width: 100%;
    padding-left: 3em !important;
  }

  @media screen and (max-width: 1280px) {
    .row > h3 {
      padding-left: 2.2em !important;
    }
  }

  @media screen and (max-width: 736px) {
    .row > h3 {
      padding-left: 1.88em !important;
    }
  }
</style>
<style scoped>
  
</style>